import { useQuery } from '@apollo/client';
import { CommonActions, useIsFocused } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import noop from 'lodash/noop';
import { memo, useEffect, useRef, useState } from 'react';
import { defineMessages } from 'react-intl';
import { SafeAreaView } from 'react-native';
import { useDebounce } from 'use-debounce';

import { useAppContext } from '@oui/app-core/src/components/AppContext';
import { useActiveRoute } from '@oui/app-core/src/hooks/useActiveRoute';
import { QuizSetProductVariantContext } from '@oui/app-core/src/screens/QuizSet';
import { graphql } from '@oui/lib/src/graphql/tada';
import { MyStoryMyPlanState } from '@oui/lib/src/types/avro';

import { Breadcrumbs } from '@src/components/Breadcrumbs';
import { Button } from '@src/components/Button';
import { Icon } from '@src/components/Icon';
import { LinkButton } from '@src/components/LinkButton';
import { Modal } from '@src/components/Modal';
import { MobileHeaderStep } from '@src/components/PatientMyStoryMyPlanContainer';
import { OldHeading, Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { DEFAULT_HEADER_MODE } from '@src/constants';
import {
  MyStoryMyPlanCompositionDataContext,
  MyStoryMyPlanCompositionDataHash,
  useMyStoryMyPlanCompositionSections,
  useMyStoryMyPlanCompositionSectionSubscription,
  useMyStoryMyPlanCompositionSectionSubscriptionData,
  usePatientPresence,
} from '@src/hooks/useComposition';
import { FakeDimensionsContext, useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { useI18n } from '@src/lib/i18n';
import { ClinicianMyPlanIntroduction } from '@src/screens/ClinicianMyPlanIntroduction';
import { ClinicianMyPlanReview } from '@src/screens/ClinicianMyPlanReview';
import { ClinicianMyStoryIntroduction } from '@src/screens/ClinicianMyStoryIntroduction';
import { ClinicianMyStoryMyPlanComplete } from '@src/screens/ClinicianMyStoryMyPlanComplete';
import { ClinicianMyStoryMyPlanOverview } from '@src/screens/ClinicianMyStoryMyPlanOverview';
import { ClinicianMyStoryTimeline } from '@src/screens/ClinicianMyStoryTimeline';
import { ControlledMyStoryMyPlan } from '@src/screens/ControlledMyStoryMyPlan';
import { useTheme } from '@src/styles';
import {
  ClinicianMyStoryMyPlanStackParamList,
  ClinicianMyStoryMyPlanStackScreenProps,
  ProductVariant,
} from '@src/types';

const PatientForMyStoryMyPlanQuery = graphql(`
  query PatientForMyStoryMyPlan($patientID: UUID!) {
    getPatient: patientByPatientID(patientID: $patientID) {
      __typename
      patient {
        __typename
        ID
        productVariant
        productVersion
        onboardingVariant
        person {
          givenName
          familyName
        }
      }
    }
  }
`);

const SCREEN_ORDER: Array<undefined | keyof ClinicianMyStoryMyPlanStackParamList> = [
  'MyStoryMyPlanOverview',
  'MyStoryIntroduction',
  'MyStoryTimeline',
  'MyStoryTimelineReview',
  'MyStoryTimelineFinal',
  'MyStoryRiskCurveIntroduction',
  'MyStoryRiskCurveReview',
  'MyStoryMyPlanIntroduction',
  'MyStoryMyPlanReview',
  'MyStoryMyPlanComplete',
];

const STEP_BY_SCREEN: Record<
  keyof ClinicianMyStoryMyPlanStackParamList,
  MyStoryMyPlanState['currentStep']
> = {
  MyStoryMyPlanOverview: 'OVERVIEW',
  MyStoryIntroduction: 'MYSTORY__INTRODUCTION',
  MyStoryTimeline: 'MYSTORY__TIMELINE',
  MyStoryTimelineReview: 'MYSTORY__TIMELINE_REVIEW',
  MyStoryTimelineFinal: 'MYSTORY__TIMELINE_FINAL',
  MyStoryRiskCurveIntroduction: 'RISK_CURVE__INTRODUCTION',
  MyStoryRiskCurveReview: 'RISK_CURVE__REVIEW',
  MyStoryMyPlanIntroduction: 'MY_PLAN__INTRODUCTION',
  MyStoryMyPlanReview: 'MY_PLAN__REVIEW',
  MyStoryMyPlanComplete: 'COMPLETE',
};

const titleByScreen = defineMessages<keyof ClinicianMyStoryMyPlanStackParamList>({
  MyStoryMyPlanOverview: { id: 'MyStoryMyPlanStack_overviewTitle', defaultMessage: 'Overview' },
  MyStoryIntroduction: {
    id: 'MyStoryMyPlanStack_introductionTitle',
    defaultMessage: 'What is MyStory',
  },
  MyStoryTimeline: { id: 'MyStoryMyPlanStack_timelineTitle', defaultMessage: 'Tell your story' },
  MyStoryTimelineReview: {
    id: 'MyStoryMyPlanStack_timelineReviewTitle',
    defaultMessage: 'Empty crisis timeline',
  },
  MyStoryTimelineFinal: {
    id: 'MyStoryMyPlanStack_timelineFinalTitle',
    defaultMessage: 'Review timeline',
  },
  MyStoryRiskCurveIntroduction: {
    id: 'MyStoryMyPlanStack_riskCurveIntroTitle',
    defaultMessage: 'What is a risk curve',
  },
  MyStoryRiskCurveReview: {
    id: 'MyStoryMyPlanStack_riskCurveReviewTitle',
    defaultMessage: 'My risk curve',
  },
  MyStoryMyPlanIntroduction: {
    id: 'MyStoryMyPlanStack_myPlanIntro',
    defaultMessage: 'What is MyPlan',
  },
  MyStoryMyPlanReview: { id: 'MyStoryMyPlanStack_myPlanReview', defaultMessage: 'MyPlan' },
  MyStoryMyPlanComplete: { id: 'MyStoryMyPlanStack_myPlanComplete', defaultMessage: 'Wrap up' },
});

function MyStoryMyPlanHeader() {
  const { Color } = useTheme();
  const { $t } = useI18n();
  const { deeplinkConfig, navigationContainer } = useAppContext();
  const route =
    useActiveRoute<
      ClinicianMyStoryMyPlanStackScreenProps<keyof ClinicianMyStoryMyPlanStackParamList>['route']
    >();
  const { width } = useWindowDimensions();
  const isNarrow = width < 1200;

  const navigate = (routeName: string) => {
    navigationContainer?.dispatch(
      CommonActions.navigate('MyStoryMyPlan', { screen: routeName, params: route.params }),
    );
  };
  const currentRouteName = route.name;

  const currentRouteIndex = SCREEN_ORDER.findIndex((s) => s === currentRouteName);
  const prevRouteName = SCREEN_ORDER[currentRouteIndex - 1];
  const nextRouteName = SCREEN_ORDER[currentRouteIndex + 1];
  const myStoryMyPlanDeeplinkConfig =
    typeof deeplinkConfig?.screens.MyStoryMyPlan !== 'string'
      ? deeplinkConfig?.screens.MyStoryMyPlan.screens
      : {};
  const prevRouteTo = prevRouteName
    ? (myStoryMyPlanDeeplinkConfig?.[prevRouteName] as string)
    : undefined;
  const nextRouteTo = nextRouteName
    ? (myStoryMyPlanDeeplinkConfig?.[nextRouteName] as string)
    : undefined;

  const myStoryRoutes = [
    'MyStoryIntroduction',
    'MyStoryTimeline',
    'MyStoryTimelineReview',
    'MyStoryTimelineFinal',
  ];
  const isMyStory = myStoryRoutes.includes(route.name);
  const riskCurveRoutes = ['MyStoryRiskCurveIntroduction', 'MyStoryRiskCurveReview'];
  const isRiskCurve = riskCurveRoutes.includes(route.name);
  const myPlanRoutes = ['MyStoryMyPlanIntroduction', 'MyStoryMyPlanReview'];
  const isMyPlan = myPlanRoutes.includes(route.name);

  return (
    <View
      row
      style={{
        backgroundColor: Color.styleGuide.LogoLilac,
        paddingVertical: 10,
        paddingHorizontal: 40,
        borderRadius: 10,
        justifyContent: 'space-between',
      }}
    >
      <View row spacing={15}>
        <LinkButton to={prevRouteTo ?? ''} params={route.params} disabled={!prevRouteName}>
          <View row>
            <Icon name="arrow-left" color="white" />
            <Text
              text={$t({ id: 'MyStoryMyPlanState_backButton', defaultMessage: 'Back' })}
              color="white"
            />
          </View>
        </LinkButton>
        {isNarrow ? null : (
          <View style={{ width: 120 }}>
            {prevRouteName ? (
              <Text text={$t(titleByScreen[prevRouteName])} color="white" size={12} />
            ) : null}
          </View>
        )}
      </View>
      <View row style={{ width: 350, alignSelf: 'center' }}>
        <MobileHeaderStep
          num={1}
          label={$t({ id: 'MyStoryMyPlanStack_myStory', defaultMessage: 'MyStory' })}
          complete={isRiskCurve || isMyPlan}
          active={isMyStory}
          onPress={() => navigate('MyStoryIntroduction')}
          progress={
            isMyStory
              ? (myStoryRoutes.indexOf(route.name) + 1) / (myStoryRoutes.length + 1)
              : undefined
          }
        />
        <MobileHeaderStep
          num={2}
          label={$t({ id: 'MyStoryMyPlanStack_riskCurve', defaultMessage: 'Risk curve' })}
          complete={isMyPlan}
          active={isRiskCurve}
          onPress={() => navigate('MyStoryRiskCurveIntroduction')}
          progress={
            isRiskCurve
              ? (riskCurveRoutes.indexOf(route.name) + 1) / (riskCurveRoutes.length + 1)
              : undefined
          }
        />
        <MobileHeaderStep
          num={3}
          label={$t({ id: 'MyStoryMyPlanStack_myPlan', defaultMessage: 'MyPlan' })}
          complete={false}
          active={isMyPlan}
          onPress={() => navigate('MyStoryMyPlanIntroduction')}
          progress={
            isMyPlan
              ? (myPlanRoutes.indexOf(route.name) + 1) / (myPlanRoutes.length + 1)
              : undefined
          }
        />
      </View>
      <View row spacing={15}>
        {isNarrow ? null : (
          <View style={{ width: 120, alignItems: 'flex-end' }}>
            {nextRouteName ? (
              <Text text={$t(titleByScreen[nextRouteName])} color="white" size={12} />
            ) : (
              <Text
                text={$t({
                  id: 'MyStoryMyPlanStack_goHomeButton',
                  defaultMessage: 'Go to homepage',
                })}
                color="white"
                size={12}
              />
            )}
          </View>
        )}
        <LinkButton
          to={nextRouteTo ?? 'patients/:patientID'}
          params={route.params}
          testID={
            nextRouteName ? 'MyStoryMyPlanHeader_nextButton' : 'MyStoryMyPlanHeader_homeButton'
          }
        >
          <View
            row
            style={{
              borderColor: 'white',
              borderWidth: 2,
              borderRadius: 22,
              paddingVertical: 10,
              paddingHorizontal: 14,
            }}
          >
            {nextRouteName ? (
              <>
                <Text
                  text={$t({ id: 'MyStoryMyPlanStack_nextButton', defaultMessage: 'Next' })}
                  color="white"
                />
                <Icon name="arrow-right" color="white" />
              </>
            ) : (
              <>
                <Icon name="home" color="white" style={{ marginRight: 8 }} />
                <Text
                  text={$t({ id: 'MyStoryMyPlanStack_homeButton', defaultMessage: 'Home' })}
                  color="white"
                />
              </>
            )}
          </View>
        </LinkButton>
      </View>
    </View>
  );
}

function Title({ text }: { text: string }) {
  const { Color } = useTheme();
  return (
    <View>
      <OldHeading text={text} />
      <View
        style={{
          backgroundColor: Color.secondary,
          height: 2,
          width: 60,
        }}
      />
    </View>
  );
}

const MyStoryMyPlanStack = createStackNavigator<ClinicianMyStoryMyPlanStackParamList>();
const ClinicianView = () => {
  const { update } = useMyStoryMyPlanCompositionSections({ createIfUndefined: true });
  const { data } = useMyStoryMyPlanCompositionSectionSubscriptionData();
  const route =
    useActiveRoute<
      ClinicianMyStoryMyPlanStackScreenProps<keyof ClinicianMyStoryMyPlanStackParamList>['route']
    >();
  const syncCountRef = useRef(0);
  const [syncClashing, setSyncClashing] = useState(false);
  const syncStateBacklogRef = useRef<{ data: Partial<typeof data> | null; inflight: boolean }>({
    data: null,
    inflight: false,
  });

  const currentRouteName = route.name;
  const expectedCurrentStep = STEP_BY_SCREEN[currentRouteName];
  const isOutOfSync = data && expectedCurrentStep !== data?.APP_STATE.currentStep;

  // If two users are on the flow at the same time, their respective useEffect's clash and turn into
  // an infinite loop. By keeping track of syncCountRef we can disable syncing if we detect infinite
  // looping
  useEffect(() => {
    syncCountRef.current = 0;
  }, [currentRouteName]);

  useEffect(() => {
    if (data && isOutOfSync && !syncClashing) {
      syncCountRef.current++;
      if (syncCountRef.current > 2) {
        setSyncClashing(true);
      }
      const currentEditor =
        expectedCurrentStep === 'MY_PLAN__REVIEW' ? data?.APP_STATE.currentEditor : 'CLINICIAN';

      const updatedData: Partial<typeof data> = {
        APP_STATE: { currentStep: expectedCurrentStep, currentEditor },
      };

      // If going to MY_PLAN for the first time add warning signs from previous steps
      if (expectedCurrentStep === 'MY_PLAN__INTRODUCTION' && data.WARNING_SIGNS.length === 0) {
        updatedData.WARNING_SIGNS = data.CRISIS_TIMELINE.timeline
          .filter((event) => event.isWarningSign)
          .map((event) => ({
            ID: event.ID,
            text: event.text,
          }));
      }

      async function updateAndCheckBacklog(latestData: Partial<MyStoryMyPlanCompositionDataHash>) {
        syncStateBacklogRef.current = { data: null, inflight: true };
        await update(latestData);
        syncStateBacklogRef.current.inflight = false;
        if (syncStateBacklogRef.current.data) {
          await updateAndCheckBacklog(syncStateBacklogRef.current.data);
        }
      }

      if (syncStateBacklogRef.current.inflight) {
        syncStateBacklogRef.current.data = { ...syncStateBacklogRef.current.data, ...updatedData };
      } else {
        updateAndCheckBacklog(updatedData);
      }
    }
  }, [expectedCurrentStep, currentRouteName, data, update, syncClashing, isOutOfSync]);

  return (
    <>
      <ClinicianViewInner />
      {syncClashing ? (
        <Modal visible={true} heading="Syncing issue" onRequestClose={noop}>
          <View spacing={24}>
            <Text text="Another user has opened MyStoryMyPlan for this patient. Currently MyStoryMyPlan only supports a single clinician user at a time." />
            <Text text="Please coordinate with the other users in your practice to prevent syncing issues." />
            <Button onPress={() => window.location.reload()} text="Reload page" />
          </View>
        </Modal>
      ) : null}
    </>
  );
};

const ClinicianViewInner = memo(() => {
  return (
    <MyStoryMyPlanStack.Navigator
      screenOptions={() => ({
        headerMode: DEFAULT_HEADER_MODE,
        headerShown: false,
      })}
    >
      <MyStoryMyPlanStack.Screen
        name="MyStoryMyPlanOverview"
        component={ClinicianMyStoryMyPlanOverview}
        initialParams={{}}
      />
      <MyStoryMyPlanStack.Screen
        name="MyStoryIntroduction"
        component={ClinicianMyStoryIntroduction}
        initialParams={{}}
      />
      <MyStoryMyPlanStack.Screen
        name="MyStoryTimeline"
        component={ClinicianMyStoryTimeline}
        initialParams={{}}
      />
      <MyStoryMyPlanStack.Screen
        name="MyStoryTimelineReview"
        component={ClinicianMyStoryTimeline}
        initialParams={{}}
      />
      <MyStoryMyPlanStack.Screen
        name="MyStoryTimelineFinal"
        component={ClinicianMyStoryTimeline}
        initialParams={{}}
      />
      <MyStoryMyPlanStack.Screen
        name="MyStoryRiskCurveIntroduction"
        component={ClinicianMyStoryTimeline}
        initialParams={{}}
      />
      <MyStoryMyPlanStack.Screen
        name="MyStoryRiskCurveReview"
        component={ClinicianMyStoryTimeline}
        initialParams={{}}
      />
      <MyStoryMyPlanStack.Screen
        name="MyStoryMyPlanIntroduction"
        component={ClinicianMyPlanIntroduction}
        initialParams={{}}
      />
      <MyStoryMyPlanStack.Screen
        name="MyStoryMyPlanReview"
        component={ClinicianMyPlanReview}
        initialParams={{}}
      />
      <MyStoryMyPlanStack.Screen
        name="MyStoryMyPlanComplete"
        component={ClinicianMyStoryMyPlanComplete}
        initialParams={{}}
      />
    </MyStoryMyPlanStack.Navigator>
  );
});

function PatientView() {
  const route =
    useActiveRoute<
      ClinicianMyStoryMyPlanStackScreenProps<keyof ClinicianMyStoryMyPlanStackParamList>['route']
    >();
  const { Color } = useTheme();
  const patientID = route.params?.patientID;
  const [fakeDimensions, setFakeDimensions] = useState({ width: 0, height: 0 });
  const patientPresence = usePatientPresence(patientID);
  const isPatientConnected = !!patientPresence?.patientPresence?.connected;

  return (
    <View
      flex={1}
      style={{
        borderWidth: 10,
        borderRadius: 41,
        borderColor: '#dee0e5',
        height: 750,
        overflow: 'hidden',
      }}
      onLayout={(e) => {
        const { width, height } = e.nativeEvent.layout;
        if (width !== fakeDimensions.width || height !== fakeDimensions.height) {
          setFakeDimensions(e.nativeEvent.layout);
        }
      }}
    >
      <View
        style={{
          zIndex: 1,
          position: 'absolute',
          top: -10,
          left: '20%',
          right: '20%',
          height: 30,
          borderRadius: 41,
          backgroundColor: '#dee0e5',
          justifyContent: 'center',
        }}
        row
        spacing={5}
      >
        <View
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: isPatientConnected ? Color.success : Color.error,
          }}
        />
        <Text text={isPatientConnected ? 'Active' : 'Inactive'} size={13} />
      </View>
      <FakeDimensionsContext.Provider value={fakeDimensions}>
        <ControlledMyStoryMyPlan hasParentSubscriptionContext />
      </FakeDimensionsContext.Provider>
    </View>
  );
}

export function MyStoryMyPlan() {
  const route =
    useActiveRoute<
      ClinicianMyStoryMyPlanStackScreenProps<keyof ClinicianMyStoryMyPlanStackParamList>['route']
    >();
  const { Color } = useTheme();
  const patientID = route.params?.patientID;

  const { data } = useQuery(PatientForMyStoryMyPlanQuery, {
    variables: { patientID },
    skip: !patientID,
  });
  const { data: subscriptionData } = useMyStoryMyPlanCompositionSectionSubscription();
  const isFocused = useIsFocused();

  const patientName = data?.getPatient
    ? `${data.getPatient.patient.person.givenName} ${data.getPatient.patient.person.familyName}`
    : 'Patient';

  const { width: _width } = useWindowDimensions();
  const [width] = useDebounce(_width, 100);

  return isFocused ? (
    <SafeAreaView style={{ flex: 1, backgroundColor: Color.grayBackground }}>
      <View flex={1} style={{ padding: 20, paddingBottom: 0 }}>
        <Breadcrumbs
          crumbs={[
            { label: 'Patients', to: 'patients' },
            { label: patientName, to: 'patients/:patientID', params: { patientID } },
            { label: 'MyStory & MyPlan', to: '' },
          ]}
        />
        <MyStoryMyPlanHeader />
        <MyStoryMyPlanCompositionDataContext.Provider value={subscriptionData}>
          {width >= 768 ? (
            <View flex={1} row style={{ alignItems: 'stretch', paddingTop: 20 }} spacing={20}>
              <View flex={1} spacing={20}>
                <Title text="Clinician View" />
                <View
                  style={{ backgroundColor: 'white', borderRadius: 10, overflow: 'hidden' }}
                  flex={1}
                >
                  <ClinicianView />
                </View>
              </View>
              <View style={{ width: 350 }} spacing={20}>
                <Title text="Patient View" />
                <QuizSetProductVariantContext.Provider
                  value={data?.getPatient?.patient.productVariant ?? ProductVariant.AVIVA_ADULT}
                >
                  <PatientView />
                </QuizSetProductVariantContext.Provider>
              </View>
            </View>
          ) : (
            <View spacing={20} flex={1} style={{ marginTop: 20 }}>
              <Title text="Clinician View" />
              <View style={{ backgroundColor: 'white', borderRadius: 10, flex: 1 }}>
                <ClinicianView />
              </View>
            </View>
          )}
        </MyStoryMyPlanCompositionDataContext.Provider>
      </View>
    </SafeAreaView>
  ) : null;
}

import { StackActions, useFocusEffect } from '@react-navigation/native';
import parseISO from 'date-fns/parseISO';
import { useCallback, useMemo, useState } from 'react';

import { useAppContext } from '@oui/app-core/src/components/AppContext';
import { formatRelativeTime } from '@oui/lib/src/formatRelativeTime';

import { Icon } from '@src/components/Icon';
import { PickerInput } from '@src/components/PickerInput';
import { PlatformScreen } from '@src/components/PlatformScreen';
import { ListItemStyle, StaffTable, TableCell } from '@src/components/StaffTable';
import { TableNameHeader } from '@src/components/TableNameHeader';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { useTheme } from '@src/styles';
import { CliniciansScreenProps } from '@src/types';

import {
  OrganizationsListQuery,
  useOrganizationsListQuery,
} from './Organizations.graphql.generated';

type OrgWithChildren = NonNullable<OrganizationsListQuery['organizations']>[0];
type OrgWithoutChildren = Omit<OrgWithChildren, 'children'>;

const SORT_ORDER = {
  ASCENDING: 'A_TO_Z',
  DESCENDING: 'Z_TO_A',
} as const;
type SORT_ORDER = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];

type ItemPosition = 'parent-with-children' | 'parent-without-children' | 'child' | 'last-child';
const getItemPosition = (item: OrgWithoutChildren & { addSpace?: boolean }): ItemPosition => {
  return item.parent === null
    ? item.addSpace
      ? 'parent-without-children'
      : 'parent-with-children'
    : item.addSpace
      ? 'last-child'
      : 'child';
};

const OrganizationItem = ({ item }: { item: OrgWithoutChildren & { addSpace?: boolean } }) => {
  const { Color } = useTheme();
  const { locale } = useAppContext();
  const itemPosition = getItemPosition(item);

  return (
    <>
      <View
        style={[
          {
            backgroundColor: '#dee0e5',
            width: 2,
            left: 22,
            position: 'absolute',
          },
          itemPosition === 'parent-without-children'
            ? null
            : itemPosition === 'parent-with-children'
              ? {
                  top: '70%',
                  height: '30%',
                }
              : itemPosition === 'child'
                ? { height: '100%', marginTop: -8 }
                : { bottom: '60%', top: 0 },
        ]}
      />
      {itemPosition === 'child' || itemPosition === 'last-child' ? (
        <View
          style={{
            backgroundColor: '#dee0e5',
            height: 1,
            position: 'absolute',
            top: -1,
            left: 60,
            right: 0,
          }}
        />
      ) : null}
      {item.parent !== null ? (
        <View
          style={[
            {
              borderBottomStartRadius: 10,
              borderColor: '#dee0e5',
              borderBottomWidth: 2,
              borderStartWidth: 2,
              width: 20,
              height: 20,
              bottom: '50%',
              left: 22,
              position: 'absolute',
            },
          ]}
        />
      ) : null}
      <TableCell grow={4}>
        {/* parents have a parent property set to null, a child can have no parent property so checking for undefined will render incorrect icon  */}
        {item.parent === null ? (
          <View>
            <Icon
              name="parent-organization"
              style={{ paddingRight: 5 }}
              color={Color.styleGuide.Gray5}
            />
          </View>
        ) : (
          <>
            <Icon
              name="child-organization"
              style={{
                marginLeft: 25,
              }}
              color={Color.styleGuide.Gray5}
            />
          </>
        )}
        <Text text={item.name ?? 'Unknown'} weight="semibold" />
      </TableCell>
      <TableCell>
        <Text text={item.stats?.registrarCount?.toString() || ''} />
      </TableCell>
      <TableCell>
        <Text text={item.stats?.practitionerCount?.toString() || ''} />
      </TableCell>
      <TableCell>
        <Text text={item.stats?.patientCount?.toString() || ''} />
      </TableCell>
      <TableCell>
        <Text
          text={
            item.lastPatientCreatedAt
              ? formatRelativeTime(locale, parseISO(item.lastPatientCreatedAt))
              : 'Never'
          }
        />
      </TableCell>
    </>
  );
};

export function Organizations(props: CliniciansScreenProps<'Organizations'>) {
  const { data, loading, refetch } = useOrganizationsListQuery({ variables: { parentOnly: true } });
  const [selectedSortOrder, setSelectedSortOrder] = useState<SORT_ORDER>(SORT_ORDER.ASCENDING);

  const flatOrganizations = useMemo(() => {
    function sortOrganizations<T extends OrgWithChildren | OrgWithoutChildren>(
      orgs: ReadonlyArray<T>,
      sortOrder: SORT_ORDER,
    ) {
      let sortedArray = [...orgs];
      if (sortOrder === SORT_ORDER.ASCENDING) {
        sortedArray.sort((a, b) => {
          if ((a.name || '') > (b.name || '')) return 1;
          return -1;
        });
      } else {
        sortedArray.sort((a, b) => {
          if ((a.name || '') < (b.name || '')) return 1;
          return -1;
        });
      }
      return sortedArray;
    }

    let allOrganizations = [];
    const organizations = [...(data?.organizations || [])];

    const sortedParents = sortOrganizations(organizations, selectedSortOrder);

    for (let i = 0; i < sortedParents.length || 0; i++) {
      allOrganizations.push({
        ...sortedParents[i],
        addSpace: sortedParents[i].children.length > 0 ? false : true,
      });

      const childOrgs = [...sortedParents[i].children];
      // @ts-expect-error This should go away when we migrate to gql.tada
      const sortedChildren = sortOrganizations(childOrgs, selectedSortOrder);

      for (let j = 0; j < sortedChildren.length; j++) {
        let lastChild = { addSpace: false };
        if (j === sortedChildren.length - 1) {
          lastChild = { addSpace: true };
        }
        allOrganizations.push({ ...sortedChildren[j], ...lastChild });
      }
    }

    return allOrganizations;
  }, [data?.organizations, selectedSortOrder]);

  const { isAdmin } = useCurrentUser();

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  const addNewOrganization = () => props.navigation.navigate('NewOrganization');

  const renderItem = ({ item }: { item: OrgWithoutChildren }) => {
    return <OrganizationItem key={item.ID} item={item} />;
  };

  return (
    <PlatformScreen
      crumbs={[{ label: 'Organizations', to: '' }]}
      testID="Organizations_mainScrollView"
    >
      {isAdmin ? (
        <TableNameHeader
          headingText="Organizations"
          navAction={addNewOrganization}
          buttonText="Add organization"
          testID="Organizations_tableNameHeader"
          style={{
            backgroundColor: 'white',
            marginHorizontal: -20,
            marginTop: -20,
          }}
        >
          <PickerInput
            label="Sort by"
            labelHorizontal="small"
            items={[
              {
                label: 'Name A to Z',
                value: SORT_ORDER.ASCENDING,
              },
              {
                label: 'Name Z to A',
                value: SORT_ORDER.DESCENDING,
              },
            ]}
            value={selectedSortOrder}
            onChangeValue={(v: SORT_ORDER) => {
              setSelectedSortOrder(v);
            }}
          />
        </TableNameHeader>
      ) : null}

      <View
        style={{
          paddingHorizontal: 20,
        }}
      >
        <StaffTable
          columns={[
            { label: 'Name', grow: 4 },
            'Admins',
            'Practitioners',
            'Patients',
            'Last added patient',
          ]}
          loading={loading}
          testID="Organizations_list"
          navigationExtractor={(item, index) => {
            const itemPosition = getItemPosition(item);
            return {
              to: 'organizations/:organizationID',
              params: { organizationID: item.ID },
              action: StackActions.push('Organization', { organizationID: item.ID }),
              testID: `Organizations_list_item_${index}`,
              style: [
                ListItemStyle,
                { borderRadius: 0 },
                itemPosition === 'parent-without-children'
                  ? { borderRadius: 8 }
                  : itemPosition === 'parent-with-children'
                    ? { borderTopStartRadius: 8, borderTopEndRadius: 8 }
                    : itemPosition === 'last-child'
                      ? { borderBottomStartRadius: 8, borderBottomEndRadius: 8 }
                      : null,
              ],
            };
          }}
          ItemSeparatorComponent={(item) => {
            return item.leadingItem.addSpace === false ? null : <View style={{ height: 8 }} />;
          }}
          renderItem={renderItem}
          data={flatOrganizations}
          keyExtractor={(organization) => organization.ID}
        />
      </View>
    </PlatformScreen>
  );
}
